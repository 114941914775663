.nav-div {
    display: inline;
    font-size: 30px;
}

.nav-list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 5vh;
}

.nav-item {
    list-style: none;
    margin-right: 2rem;
}

.nav-item a {
    display: inline-block;
    position: relative;
    text-decoration: none;
    color: white;
}

.first {
    margin-right: auto;
    margin-left: 2rem;
}

.nav-item a:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #f1c40f;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}
  
.nav-item a:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}