.project {
    background-color: #1e272e;
    border-radius: 10px;
    width: 150px;
    height: 200px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.project:hover {
    background-color: #485460;
}

.project-title {
    flex: 6;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 25px;
}

.project-date {
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tags {
    gap: 5px;
    cursor: default;
}

.tag {
    padding: 5px 10px;
    border-radius: 100px;
    width: fit-content;
    text-align: center;
}

.frame {
    padding: 5px 10px;
    border-radius: 100px;
    width: fit-content;
    background-color: #858585;
    text-align: center;
}

#react {
    background-color: #e74c3c;
}

#javascript {
    background-color: #3498db;
}
