#mobile-nav {
    text-align: center;
    margin-top: 20px;
}


#mobile-title {
	text-align: center;
}

#mobile-title h1 {
	margin-top: 25vh;
	font-size: 30vw;
	font-family: 'Pacifico', cursive;
	color: transparent;
	background-image: linear-gradient(to right, #e74c3c , #f1c40f,#3498db, #9b59b6);
	background-size: 400% 400%;
	background-clip: text;
	animation: animatedBackground 8s ease infinite;
}


#mobile-title {
	color: white;
}

#mobile-title-center-flex {
	display: flex;
	width: 100%;
	justify-content: center;
	margin-top: 75px;
}

#mobile-welcome {
	width: 50vw;
	height: auto;
	display: block;
	margin: auto;
	margin-top: 30px;
}

#mobile-title-grid {
	display: grid;
	width: 75%;
	grid-template-rows: 50% 50%;
	/* grid-column: auto; */
	justify-content: center;
	gap: 15px;
}

#mobile-title-grid img {
	width: 60%;
	height: auto;
	display: block;
	margin-left: 50%;
	transform: translate(-50%);
}

#mobile-title-grid h3 {
	font-size: 5vw;
	margin-left: 8px;
}

#mobile-title-grid-h1 {
	font-size: 6vw;
	text-align: center;
	margin-top: 0;
	color: var(--accent-color);
}

@keyframes animatedBackground {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.mobile-main-hr {
	border: 1px white solid;
	margin: auto;
	margin-top: 8vh;
}

.mobile-lines {
	display: block;
	z-index: 1;
}

#mobile-first {
	width: 30%;
	animation: lines var(--line-animation) infinite;
}

#mobile-second {
	width: 45%;
	animation: lines var(--line-animation) infinite;
	animation-delay: 0.2s;
}

#mobile-third {
	width: 60%;
	animation: lines var(--line-animation) infinite;
	animation-delay: 0.4s;
}

#mobile-fourth {
	width: 70%;
	animation: lines var(--line-animation) infinite;
	animation-delay: 0.6s;
}

#mobile-fifth {
	width: 85%;
	animation: lines var(--line-animation) infinite;
	animation-delay: 0.8s;
}

@keyframes lines {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 100%;
	}
	100% {
		opacity: 0;
	}
}

#mobile-skills {
    display: flex;
    /* background-color: #2197A9; */
    background-color: black;
    color: #4cbbcd;
    /* height: 100vh; */
    margin-bottom: 150px;
    width: 100%;
    align-items: center;
    flex-direction: column;
}

#mobile-skills h1 {
    font-family: 'Pacifico', cursive;
    font-size: 12vw;
    padding-top: 100px;
    text-align: center;
    margin-bottom: 40px
}

.mobile-skills-flex {
    display: flex;
    width: 90%;
    align-items: center;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
	color: white;
}

.mobile-skills-flex div {
    background-color: black;
    border: 2px solid #4cbbcd;
    border-radius: 25px;
    text-align: center;
}

.mobile-skills-flex div h3 {
    margin-top: 20px;
    font-size: 8vw;
}

.mobile-skills-flex div hr {
    margin-top: 20px;
}

.mobile-skills-flex div p {
    margin: 10px;
    text-align: left;
    font-size: 5vw;
}