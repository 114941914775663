:root {
	--line-animation: 2.5s;
}

#title {
	color: white;
}

#title-center-grid {
	display: flex;
	width: 100%;
	justify-content: center;
	margin-top: 150px;
}

#welcome {
	width: 25vw;
	height: auto;
	display: block;
	margin: auto;
}

.path {
	stroke-width: 10;
	stroke-dasharray: 1377;
	stroke-dashoffset: 1377;
	animation: dash 3s linear forwards;
}
  
@keyframes dash {
	to {
		stroke-dashoffset: 0;
	}
}

#title-h1 {
	font-size: 6vw;
	font-family: 'Pacifico', cursive;
	text-align: center;
	color: transparent;
	background-image: linear-gradient(to right, #e74c3c , #f1c40f,#3498db, #9b59b6);
	background-size: 400% 400%;
	background-clip: text;
	animation:
	animatedBackground 8s ease infinite;
}

#title-grid {
	display: grid;
	width: 75%;
	grid-template-columns: 50% 50%;
	justify-content: center;
	gap: 15px;
}

#title-grid img {
	width: clamp(200px, 50%, 400px);
	height: auto;
	margin-left: 100px;
}

#title-grid-h1 {
	font-size: 3vw;
	margin-top: 0;
}

@keyframes animatedBackground {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.main-hr {
	border: 1px white solid;
	margin: auto;
	margin-top: 10vh;
}

.lines {
	display: block;
	z-index: 1;
	margin-bottom: 50px;
}

#first {
	width: 20%;
	animation: lines var(--line-animation) infinite;
}

#second {
	width: 30%;
	animation: lines var(--line-animation) infinite;
	animation-delay: 0.2s;
}

#third {
	width: 40%;
	animation: lines var(--line-animation) infinite;
	animation-delay: 0.4s;
}

#fourth {
	width: 50%;
	animation: lines var(--line-animation) infinite;
	animation-delay: 0.6s;
}

#fifth {
	width: 60%;
	animation: lines var(--line-animation) infinite;
	animation-delay: 0.8s;
}

#sixth {
	width: 70%;
	animation: lines var(--line-animation) infinite;
	animation-delay: 1s;
}

@keyframes lines {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 100%;
	}
	100% {
		opacity: 0;
	}
}