.ball_476 {
    animation: 1s linear 0s 1 normal forwards running ball_476;
    transform: translateX(617px) translateY(119px);
}
@keyframes ball_476 {
    0% {
        transform: translateX(617px) translateY(119px);
    }
    25% {
        transform: translateX(539px) translateY(105px);
    }
    50% {
        transform: translateX(460px) translateY(90px);
    }
    75% {
        transform: translateX(382px) translateY(95px);
    }
    100% {
        transform: translateX(304px) translateY(101px);
    }
}
.flight_445 {
    animation: 0.1s linear 0.9s 1 normal forwards running flight_445;
    opacity: 0;
}
@keyframes flight_445 {
    0% {
        opacity: 0;
    }
    90% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
