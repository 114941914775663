.italics {
    font-style: italic;
}

#mobile-resume {
    background-color: black;
    color: white;
}

#mobile-resume-title {
    text-align: center;
    font-style: italic;
    font-size: 10vw;
    margin-bottom: 25px;
}

.mobile-resume-headings {
    font-size: 8vw;
    padding-left: 3vw;
}

.mobile-resume-hr {
    border: 2px solid var(--accent-color);
    padding: 0;
}

.mobile-resume-grid {
    display: grid;
    grid-auto-columns: 50% 50%;
    margin-top: 30px;
    grid-row-gap: 50px;
}

.mobile-resume-subheading {
    font-size: 4vw;
    padding-left: 3vw;
}

.mobile-resume-subtitles {
    font-size: 3vw;
    padding-left: 3vw;
    color: grey;
}

.mobile-resume-dates {
    grid-column-start: 2;
    font-size: 3vw;
    text-align: right;
    padding-right: 25px;
    padding-top: 5px;
}

.mobile-resume-job-description {
    margin-left: 10vw;
    padding-top: 10px;
    font-size: 3vw;
    width: 85%;
}

.mobile-resume-edu-description {
    margin-left: 3vw;
    padding-top: 5px;
    font-size: 3vw;
    width: 85%;
}

.mobile-edu-subheading-block {
    margin-top: 15px;
}