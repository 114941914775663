#mobile-contact {
    background-color: black;
    color: white;
    padding-bottom: 200px;
    padding-top: 100px;
}

#mobile-contact-title {
    text-align: center;
    font-size: 10vw;
}

#mobile-contact-grid {
    display: grid;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 50vw;
    transform: translateX(-50%);
    width: 35vw;
    grid-auto-flow: column;
    gap: 30px;
}

.mobile-contact-img {
    width: auto;
    height: 60px;
}