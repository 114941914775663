@import url('https://fonts.googleapis.com/css2?family=Lato&family=Pacifico&display=swap');

:root {
    --main-color: #ecf0f1;
    --accent-color: #e74c3c;
}

* {
    font-family: 'Lato', sans-serif;
    margin: 0;
    padding: 0;
}

body {
    background-color: black;
    color: white;
}

html {
    scroll-behavior: smooth;
}

.flex-align {
    display: flex;
    align-items: center;
}
