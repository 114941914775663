.Group_5_923 {
    animation: 0.5s linear 0s 1 normal forwards running Group_5_923;
    opacity: 0;
}
@keyframes Group_5_923 {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.Ellipse_1_689 {
    animation: 0.7s linear 0.4s 1 normal forwards running Ellipse_1_689;
    transform: translateX(30.430000000000007px) translateY(25px);
    opacity: 0;
}
@keyframes Ellipse_1_689 {
    0% {
        transform: translateX(30.430000000000007px) translateY(25px);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: translateX(30.430000000000007px) translateY(35px);
        opacity: 1;
    }
}
.Ellipse_2_697 {
    animation: 0.9s linear 0.6s 1 normal forwards running Ellipse_2_697;
    transform: translateX(44.370000000000005px) translateY(25px);
    opacity: 0;
}
@keyframes Ellipse_2_697 {
    0% {
        transform: translateX(44.370000000000005px) translateY(25px);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: translateX(44.370000000000005px) translateY(35px);
        opacity: 1;
    }
}
.Ellipse_3_891 {
    animation: 1.1s linear 0.8s 1 normal forwards running Ellipse_3_891;
    transform: translateX(58.32000000000005px) translateY(25px);
    opacity: 0;
}
@keyframes Ellipse_3_891 {
    0% {
        transform: translateX(58.32000000000005px) translateY(25px);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: translateX(58.32000000000005px) translateY(35px);
        opacity: 1;
    }
}
.Ellipse_4_397 {
    animation: 1.2s linear 0.9s 1 normal forwards running Ellipse_4_397;
    transform: translateX(72.25999999999999px) translateY(25px);
    opacity: 0;
}
@keyframes Ellipse_4_397 {
    0% {
        transform: translateX(72.25999999999999px) translateY(25px);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: translateX(72.25999999999999px) translateY(35px);
        opacity: 1;
    }
}
