.projects-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 100px 0;
}

.projects-title {
    font-size: 60px;
    text-align: center;
    margin: 50px;
}

.projects-hr {
    width: 75%;
}

.projects-list {
    margin-top: 75px;
    display: flex;
    flex-direction: row;
    width: 85%;
    justify-content: center;
    gap: 25px;
    flex-wrap: wrap;
}

.spikestat {
    transition: 1s;
}

.spikestathover {
    margin-left: 100px;
    margin-top: -142px;
    cursor: pointer;
}

.cryptmsg {
    margin-top: 25px;
    cursor: pointer;
}
